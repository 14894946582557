<template>
  <div class="card">
    <h1 class="tw-text-xl tw-font-bold tw-mb-8">Tambah Kategori</h1>
    <div>
      <CategoryForm @submit="createSalonService" />
    </div>
  </div>
</template>

<script setup>
import {useCategoryForm} from "@/composables/category";
import {useRouter} from "vue-router";
import CategoryForm from "./Form";

const {create} = useCategoryForm()
const router = useRouter();

const createSalonService = async (data) => {
  await create(data)
  await router.push({name: 'categories'})
}
</script>

<style scoped>

</style>